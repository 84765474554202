<template>
  <a-table
    id="record-data-devices-table"
    :columns="columns"
    :data-source="activeDevices"
    :loading="isLoadingDevices"
    :scroll="{ x: true }"
    class="w-100"
    :pagination="{ position: ['bottomCenter'] }"
  >
    <template #title>
      <a-typography-title :level="5">
        Device(s) Active: &nbsp;
        <a-tag id="record-active-devices-count" color="blue">
          {{ activeDevicesCount }}
        </a-tag>
      </a-typography-title>
    </template>

    <template #emptyText>
      <div
        id="no-active-devices-found-record-data"
        style="height: 40vh"
        class="d-flex flex-column align-items-center justify-content-center"
      >
        <laptop-outlined style="fontsize: 40px" />
        <span class="mt-2">No Active Device(s) Found</span>
      </div>
    </template>

    <template
      :id="'rd-device-' + title + '-header-cell'"
      #headerCell="{ title }"
    >
      <span class="text-center">{{ title }}</span>
    </template>

    <template #bodyCell="{ column, record, index }">
      <div>
        <div v-if="column.dataIndex === 'deviceId'" class="text-center">
          {{ record.display_name || record.Serial_number }}
        </div>

        <div v-if="column.dataIndex === 'status'" class="text-center">
          <div
            style="width: 100%"
            class="d-flex justify-content-center align-items-center"
          >
            <span
              v-if="Object.keys(record?.running_schedule || {}).length !== 0"
              class="mr-2 schedule-indicator"
            >
              <a-tooltip title="Schedule Running">
                <CalendarOutlined class="icon-style" />
              </a-tooltip>
            </span>
            <div
              v-if="record.isInferenceRunning && record.recordInference"
              class="tag-group"
            >
              <a-tag color="success">Guidance</a-tag>
              <a-tag color="success">Trace</a-tag>
            </div>
            <a-tag
              v-else-if="record.isInferenceRunning"
              color="blue"
              class="mt-2"
            >
              Guidance
            </a-tag>
            <a-tag v-else-if="record.recordInference" color="blue" class="mt-2">
              Trace
            </a-tag>
            <a-tag
              v-else-if="
                record.record_shift ||
                (record.is_shift_managed && record.recording)
              "
              :id="'rd-device-' + index + '-recording-status'"
              color="error"
            >
              <template #icon>
                <video-camera-outlined />
              </template>
              Recording
            </a-tag>
            <a-tag v-else color="default"> Idle </a-tag>
            <a-tag
              v-if="
                record?.uploading?.progress > 0 ||
                record?.downloading?.progress > 0
              "
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
              class="mt-1 text-center"
            >
              <span>
                {{
                  record?.uploading
                    ? record?.uploading?.name
                    : record?.downloading?.name
                }}
              </span>
              <a-progress
                :percent="
                  record?.uploading
                    ? record?.uploading?.progress
                    : record?.downloading?.progress
                "
                size="small"
                style="width: 150px"
              />
              &nbsp;{{ record?.uploading ? 'Uploading' : 'Downloading' }}
            </a-tag>
          </div>
        </div>

        <div v-if="column.dataIndex === 'task'" class="text-center">
          <a-select
            :id="'rd-device-' + index + '-select-task'"
            :ref="(el) => (taskInputRefs[record.Serial_number] = el)"
            show-search
            class="w-100"
            :value="record.Task"
            placeholder="Select Operation"
            :options="taskOptions"
            :loading="isLoadingTasks"
            :disabled="
              record?.record_shift ||
              record?.isInferenceRunning ||
              record?.recordInference
            "
            size="small"
            :filter-option="filterOption"
            @change="(val) => handleChangeTask(record, val)"
          />
        </div>

        <div v-if="column.dataIndex === 'action'" class="text-center">
          <a-button
            :id="'rd-active-device-record-btn-' + index"
            :disabled="!record.Task"
            block
            type="primary"
            :loading="isRecordingLoading[record.id]"
            @click="handleRecordingBtn(record)"
          >
            {{ record?.record_shift ? 'Stop' : 'Start' }}
            Recording
          </a-button>
        </div>
      </div>
    </template>
  </a-table>
</template>

<script>
import columns from 'src/config/columns-config.js';
import daysOptions from 'src/config/days-config.js';
import spaceMixin from 'src/mixins/handleSpace';
import { useActiveDevices } from '../composables/useActiveDevices';

import {
  CalendarOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  LaptopOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons-vue';

export default {
  components: {
    LaptopOutlined,
    CloudUploadOutlined,
    VideoCameraOutlined,
    CloseOutlined,
    CalendarOutlined,
  },
  mixins: [spaceMixin],
  setup() {
    const {
      activeDevicesList,
      activeDevices,
      isScheduling,
      selectedDays,
      startEndTime,
      isLoadingDevices,
      activeDevicesCount,
      updateDeviceSettings,
      handleRecording,
      taskInputRefs,
      taskOptions,
      isLoadingTasks,
      handleChangeTask,
    } = useActiveDevices();

    return {
      columns,
      handleChangeTask,
      activeDevicesList,
      activeDevices,
      isScheduling,
      selectedDays,
      startEndTime,
      isLoadingDevices,
      activeDevicesCount,
      taskInputRefs,
      updateDeviceSettings,
      handleRecording,
      daysOptions,
      taskOptions,
      isLoadingTasks,
    };
  },
  data() {
    return {
      isRecordingLoading: {},
    };
  },
  methods: {
    async handleRecordingBtn(record) {
      const index = record.id;
      this.isRecordingLoading[index] = true;
      await this.handleRecording(record);
      this.isRecordingLoading[index] = false;
    },
  },
};
</script>
<style>
.icon-style {
  font-size: 14px;
  color: #ffc107;
  margin-top: 7px;
}
</style>
